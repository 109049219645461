<template>
  <v-container fluid>
    <!-- <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 60vh; position: relative"
    > -->
    <v-row>
      <template v-if="!isPageLoading && images.length <= 0">
        <v-col md="12">
          <p class="m-0 row-not-found text-center font-weight-500 font-size-16">
            <img
              height="35"
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image"
            />
            Uhh... There are no image at the moment.
          </p>
        </v-col>
      </template>
      <template v-else>
        <v-col md="4">
          <v-img
            :src="selectedImage"
            :lazy-src="$defaultImage"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="py-4">
            <template v-if="isPrimary">
              <v-chip color="cyan lighten-5" label>
                <i class="flaticon2-correct text-success font-size-h5 mr-2"></i>
                Primary
              </v-chip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2" v-bind="attrs" v-on="on"
                    >mdi-progress-question</v-icon
                  >
                </template>
                <span
                  >This image will be displayed in<br />all your transactions
                  for this item.</span
                >
              </v-tooltip>
            </template>
            <template v-else
              ><v-btn
                :disabled="pageLoading || isPageLoading"
                :loading="primaryLoading"
                v-on:click="setAsPrimary"
                text
                small
                color="cyan"
                class="text-white font-size-14"
                >Set as primary</v-btn
              ></template
            >
            <v-btn
              :disabled="pageLoading || isPageLoading"
              :loading="deleteLoading"
              v-on:click="deleteImage"
              icon
              small
              class="float-right"
              color="red lighten-1 font-size-14"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col md="8">
          <v-row>
            <v-col
              v-for="(image, index) in itemImages"
              :key="index"
              class="d-flex child-flex pt-0 pr-0 cursor-pointer"
              cols="2"
              v-on:click="setImage(image)"
            >
              <v-img
                :src="image.file.url"
                :lazy-src="$defaultImage"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <!-- </perfect-scrollbar> -->
  </v-container>
</template>

<script>
import { PATCH, DELETE } from "@/core/services/store/request.module";
export default {
  props: {
    type: {
      type: String,
      required: true,
      default: "product",
    },
    parent: {
      type: Number,
      required: true,
      default: 0,
    },
    isPageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    images: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      primaryLoading: false,
      deleteLoading: false,
      isPrimary: false,
      selectedImage: null,
      selectedImageId: 0,
      itemImages: new Array(),
    };
  },
  watch: {
    images: {
      deep: true,
      immediate: true,
      handler(param) {
        this.itemImages = param;
        this.setFirstImage();
      },
    },
  },
  mounted() {
    this.setFirstImage();
  },
  methods: {
    deleteImage() {
      const _this = this;
      _this.pageLoading = true;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `file-manager/delete/${_this.selectedImageId}`,
        })
        .then(() => {
          let index = _this.lodash.findIndex(_this.itemImages, function (row) {
            return row.id == _this.selectedImageId;
          });
          if (index >= 0) {
            _this.itemImages.splice(index, 1);
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.deleteLoading = false;
        });
    },
    setAsPrimary() {
      const _this = this;
      _this.pageLoading = true;
      _this.primaryLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `${_this.type}/${_this.parent}/image/${_this.selectedImageId}/primary`,
        })
        .then(({ data }) => {
          _this.itemImages = data;
          _this.setFirstImage();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.primaryLoading = false;
        });
    },
    setFirstImage() {
      const _this = this;
      let firstImage = _this.lodash.head(_this.itemImages);
      if (_this.lodash.isEmpty(firstImage) === false) {
        _this.selectedImage = firstImage.file.url;
        _this.selectedImageId = firstImage.id;
        _this.isPrimary = firstImage.primary;
      }
    },
    setImage(row) {
      this.selectedImage = row.file.url;
      this.selectedImageId = row.id;
      this.isPrimary = row.primary;
    },
  },
};
</script>
