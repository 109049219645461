<template>
  <!--begin::Product Adjustment Internal Listing-->
  <v-container fluid v-if="getPermission('product-adjustment:view')">
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllAdjustments"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_product_adjustment
                  }}</template>
                  <template v-else>{{
                    item.product_adjustment_status_count
                  }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllAdjustments"
            @keydown.tab="getAllAdjustments"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllAdjustments"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllAdjustments"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="getPermission('product-adjustment:create')">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('product.adjustment.create', {
                        query: {
                          product: parent,
                        },
                      })
                    )
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create Adjustment</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="5"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Adjustment #</th>
            <th class="simple-table-th" width="300">Basic Details</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Created Time</th>
            <th class="simple-table-th" width="200">Last Modified Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="showDetail(row)"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="adjustment-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>
                    <template v-if="row.reference">{{
                      row.reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reason: </b>
                    <template v-if="row.reason">{{ row.reason }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reason</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="adjustment-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Date: </b>
                    <template v-if="row.date">{{
                      formatDate(row.date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Date</em></template
                    >
                  </p>
                </div>
              </td>
              <td>
                <TableActivity
                  v-if="!lodash.isEmpty(row.added_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
              <td>
                <TableActivity
                  v-if="!lodash.isEmpty(row.updated_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.updated_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.modified_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.updated_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no adjustment at the moment.
              </p>
            </td>
          </tr>
        </tbody>
        <Dialog
          :commonDialog.sync="detailDialog"
          :dialogWidth.sync="dialogWidth"
        >
          <template v-slot:title>
            <template>
              <v-layout>
                <v-flex>
                  {{ adjustmentDetail.barcode }}

                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                        color="cyan"
                        text-color="white"
                        label
                      >
                        {{ adjustmentDetail.status_text }}
                      </v-chip>
                    </template>
                    <span>Status</span>
                  </v-tooltip>
                </v-flex>
                <v-flex class="text-right">
                  <template v-if="adjustmentMoreAction.length">
                    <v-menu
                      transition="slide-y-transition"
                      bottom
                      content-class="custom-menu-list"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!formValid || formLoading || dataLoading"
                          :loading="formLoading"
                          class="mx-2 custom-bold-button white--text"
                          color="cyan"
                          v-bind="attrs"
                          v-on="on"
                        >
                          More... <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <template v-for="(more, index) in adjustmentMoreAction">
                          <v-list-item
                            link
                            v-on:click="updateMoreAction(more.action)"
                            :key="index"
                            :disabled="more.disabled"
                          >
                            <v-list-item-icon
                              class="margin-auto-zero mr-3 my-2"
                            >
                              <v-icon class="icon-default-blue">{{
                                more.icon
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              class="font-weight-500 font-size-14"
                              >{{ more.title }}</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </template>
                  <v-btn
                    class="mx-2 custom-grey-border custom-bold-button"
                    v-on:click="detailDialog = false"
                  >
                    Close
                  </v-btn>
                </v-flex>
              </v-layout>
            </template>
          </template>
          <template v-slot:body>
            <v-container fluid>
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 60vh; position: relative"
              > -->
              <v-row>
                <v-col md="6">
                  <table class="width-100 font-size-16">
                    <tr>
                      <td class="font-weight-600" width="200">
                        <label>Reference #</label>
                      </td>

                      <td>{{ adjustmentDetail.reference }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-600" width="200">
                        <label>Date</label>
                      </td>

                      <td>{{ formatDate(adjustmentDetail.date) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-600" width="200">
                        <label>Reason</label>
                      </td>

                      <td>{{ adjustmentDetail.reason }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-600" width="200">
                        <label>Description</label>
                      </td>
                      <td>
                        <read-more
                          class="custom-read-more"
                          more-str="read more"
                          :text="
                            adjustmentDetail.description
                              ? adjustmentDetail.description
                              : ''
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col md="12" class="custom-line-item-template">
                  <table class="custom-border-top width-100 font-size-16">
                    <thead>
                      <tr>
                        <th colspan="2" width="500" class="font-size-16 py-2">
                          Product
                        </th>
                        <th class="pl-5 font-size-16 py-2">
                          Quantity Adjusted
                        </th>
                      </tr>
                      <tr>
                        <td
                          colspan="3"
                          class="custom-border-bottom first-border"
                        ></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="custom-line-item"
                        v-for="(item, index) in adjustmentItems"
                        :key="index"
                      >
                        <td width="150" class="pt-4">
                          <v-img
                            :lazy-src="$defaultImage"
                            :src="getImageUrl(item.product.product_image)"
                            width="100"
                            class="margin-auto"
                          >
                          </v-img>
                        </td>
                        <td class="pl-5 pt-4 font-weight-600">
                          <v-chip
                            v-if="item.product"
                            small
                            label
                            class="custom-status font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            color="cyan darken-1"
                          >
                            {{ item.product.barcode }}
                          </v-chip>
                          <p v-if="item.product" class="my-1">
                            {{ item.product.name }}
                          </p>
                          <p v-if="item.description" class="my-1">
                            {{ item.description }}
                          </p>
                        </td>
                        <td class="pl-5 pt-4">
                          {{ item.quantity }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
              <!-- </perfect-scrollbar> -->
            </v-container>
          </template>
        </Dialog>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Product Adjustment Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { GET, PATCH, QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "adjustment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataLoading: true,
      detailDialog: false,
      momentObject: moment,
      customerList: [],
      dataRows: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      adjustmentDetail: new Object(),
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllAdjustments();
        }
      },
    },
  },
  components: {
    Barcode,
    Dialog,
    ListingTable,
    TableActivity,
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_approved":
          _this.patchAdjustment({
            adjustments: [_this.adjustmentDetail.id],
            status: 2,
          });
          break;
        case "mark_as_adjusted":
          _this.patchAdjustment({
            adjustments: [_this.adjustmentDetail.id],
            status: 3,
          });
          break;
      }
    },
    patchAdjustment(data) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, { url: "product/adjustment", data })
        .then(() => {
          _this.getAllAdjustments();
          _this.detailDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getImageUrl(image) {
      if (image) {
        return image.file.url;
      }
      return null;
    },
    showDetail(row) {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "product/adjustment/" + row.id,
        })
        .then(({ data }) => {
          _this.detailDialog = true;
          _this.adjustmentDetail = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllAdjustments();
    },
    getAllAdjustments() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        product: _this.detail.id,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/adjustment",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  computed: {
    adjustmentMoreAction() {
      let result = new Array();
      if (this.lodash.isEmpty(this.adjustmentDetail) === false) {
        if (this.adjustmentDetail.status < 2) {
          result.push({
            title: "Mark as Approved",
            action: "mark_as_approved",
            icon: "mdi-check-all",
            disabled: false,
          });
        }
        if (this.adjustmentDetail.status < 3) {
          result.push({
            title: "Mark as Adjusted",
            action: "mark_as_adjusted",
            icon: "mdi-check-all",
            disabled: false,
          });
        }
      }
      return result;
    },
    adjustmentItems() {
      return this.adjustmentDetail ? this.adjustmentDetail.line_items : [];
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
