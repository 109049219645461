<template>
  <!--begin::Warranty Product Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-text-field
            v-model="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getProductWarranty"
            @keydown.tab="getProductWarranty"
          ></v-text-field>
        </v-col>
        <v-col v-if="false" md="2">
          <v-autocomplete
            v-model="filter.customer"
            hide-details
            :items="customerList"
            clearable
            dense
            flat
            filled
            label="Customer"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            :item-text="'customer.display_name'"
            :item-value="'customer.customer'"
            v-on:change="getProductWarranty"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Customer(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <template v-if="item.customer">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="$assetAPIURL(item.customer.profile_logo)"
                    aspect-ratio="1"
                    class="margin-auto grey lighten-5 custom-grey-border"
                    transition="fade-transition"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.customer.display_name }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.customer.company_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action
                  v-if="item.contact_person"
                  class="align-self-center"
                >
                  <v-list-item-subtitle
                    class="text-lowercase pb-2 font-weight-500 font-size-14"
                    >{{
                      item.contact_person.primary_email
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="text-lowercase font-weight-500 font-size-14"
                    >{{
                      item.contact_person.primary_phone
                    }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-autocomplete
            v-model="filter.job"
            hide-details
            :items="jobList"
            clearable
            dense
            flat
            filled
            label="Job"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            item-text="barcode"
            item-value="id"
            v-on:change="getProductWarranty"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Job(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16 max-content-width"
                  >Job# {{ item.barcode }}</v-list-item-title
                >
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16 max-content-width"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-subtitle
                  v-if="item.property"
                  class="pb-2 pl-3 font-weight-500 font-size-14 max-content-width"
                >
                  <template v-if="item.property.unit_no"
                    >{{ item.property.unit_no }},
                  </template>
                  <template v-if="item.property.street_1"
                    >{{ item.property.street_1 }},
                  </template>
                  <template v-if="item.property.street_2"
                    >{{ item.property.street_2 }},
                  </template>
                  <template v-if="item.property.zip_code"
                    >{{ item.property.zip_code }}
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Warranty Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getProductWarranty"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getProductWarranty"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="4"
      :dataLoading.sync="dataLoading"
      :rowData.sync="warranty"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="200">Customer</th>
            <th class="simple-table-th" width="300">Job</th>
            <th class="simple-table-th" width="200">Serial No.</th>
            <th class="simple-table-th" width="200">Warranty Dates</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="warranty.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in warranty"
              v-on:click="detailDialogConfirm(row)"
            >
              <td class="simple-table-td">
                <div class="warranty-listing-product">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b>
                    {{ row.customer_display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b> {{ row.customer_company_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Email: </b>
                    {{ row.customer_primary_email }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Phone: </b>
                    {{ row.customer_primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="warranty-listing-product" v-if="row.ticket_id">
                  <div v-on:click.stop class="max-content-width">
                    <Barcode
                      route="job.detail"
                      :barcode="row.ticket_barcode"
                      :id="row.ticket_id"
                    ></Barcode>
                  </div>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Title: </b>{{ row.ticket_title }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis-two-line">
                    <b>Property: </b> {{ row.ticket_property }}
                  </p>
                </div>
                <div v-else>
                  <em>No Job</em>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <p class="m-0 custom-nowrap-ellipsis">
                  {{ row.warranty_unique_id }}
                </p>
              </td>
              <td class="simple-table-td" width="100">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Start: </b>{{ formatDate(row.warranty_start_date) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>End: </b>{{ formatDate(row.warranty_end_date) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <template
                    v-if="
                      momentObject().isAfter(
                        momentObject(row.warranty_end_date)
                      )
                    "
                  >
                    <b>Expired</b>
                  </template>
                  <template v-else>
                    <b
                      >Expiring on
                      {{ formatDate(row.warranty_end_date) }} midnight.</b
                    >
                  </template>
                </p>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-if="!dataLoading">
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no warranty at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <Dialog :commonDialog="detailDialog">
      <template v-slot:title> Warranty Detail </template>
      <template v-slot:body>
        <WarrantyDetail :detail.sync="warrantyDetail"></WarrantyDetail>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button text-white"
          color="red darken-1"
          v-on:click="voidWarrantyDialog = true"
        >
          Void
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="detailDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="voidWarrantyDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <p class="font-weight-500 font-size-18 color-custom-blue">
          Are you sure, you want to void warranty ?
        </p>
        <em class="text--secondary"
          >Note: Warranty will be removed from product of Job#
          {{ warrantyDetail.ticket ? warrantyDetail.ticket.barcode : "" }} &amp;
          cannot be retrieved later.</em
        >
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="warrantyLoading"
          :loading="warrantyLoading"
          class="mx-2 custom-grey-border custom-bold-button text-white"
          color="red darken-1"
          v-on:click="voidWarranty"
        >
          Yes
        </v-btn>
        <v-btn
          :disabled="warrantyLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="voidWarrantyDialog = false"
        >
          No
        </v-btn>
      </template>
    </Dialog>
  </v-container>
  <!--end::Warranty Product Listing-->
</template>

<script>
import moment from "moment-timezone";
import { GET, QUERY, DELETE } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import WarrantyDetail from "@/view/pages/warranty/Warranty-Detail.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "warranty-product-list",
  data() {
    return {
      product: 0,
      dates: [],
      warranty: [],
      warrantyLoading: false,
      dataLoading: true,
      detailDialog: false,
      voidWarrantyDialog: false,
      warrantyDetail: new Object(),
      momentObject: moment,
      customerList: [],
      jobList: [],
      filter: {
        search: null,
        customer: 0,
        job: 0,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        if (this.product > 0) {
          this.getProductWarranty();
          this.getFilterAttributes();
        }
      },
    },
  },
  components: {
    Barcode,
    Dialog,
    ListingTable,
    WarrantyDetail,
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getProductWarranty();
    },
    voidWarranty() {
      const _this = this;
      _this.warrantyLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url:
            "product/" + _this.product + "/warranty/" + _this.warrantyDetail.id,
        })
        .then(() => {
          _this.voidWarrantyDialog = false;
          _this.detailDialog = false;
          _this.warrantyDetail = new Object();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.warrantyLoading = false;
          _this.getProductWarranty();
        });
    },
    detailDialogConfirm(row) {
      this.warrantyDetail = row;
      this.detailDialog = true;
    },
    getProductWarranty() {
      const _this = this;

      let filter = {
        search: _this.filter.search || undefined,
        customer:
          _this.lodash.toSafeInteger(_this.filter.customer) || undefined,
        job: _this.lodash.toSafeInteger(_this.filter.job) || undefined,
        daterange: _this.dates || undefined,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.daterange = undefined;
      }

      clearTimeout(_this.timeout);
      _this.warranty = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/" + _this.product + "/warranty",
            data: { filter },
          })
          .then(({ data }) => {
            _this.warranty = data.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
    getFilterAttributes() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "product/" + _this.product + "/warranty/attributes",
        })
        .then(({ data }) => {
          _this.customerList = data.customers;
          _this.jobList = data.tickets;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {},
  beforeMount() {},
};
</script>
